import React from 'react'

const Card = (props) => {
    return (
        <div class="relative max-w-lg sm:max-w-sm  rounded-xl overflow-hidden shadow-xl bg-white mt-[30px] xl:max-w-md  ml-4">
            <img
                class="h-60 mx-auto p-8"
                src={props.img}
                alt="Sunset in the mountains"
            ></img>
            <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2 after:block after:border-2 after:mt-2 after:w-[5%] after:border-blue-300">
                    {props.title}
                </div>
                <p class="text-gray-700 text-base">{props.children}</p>
            </div>
            <div class="px-6 pt-4 pb-2 sm:p-2">
                {props.tags &&
                    props.tags.map((t, i) => (
                        <span
                            key={i}
                            class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"
                        >
                            {`#${t}`}
                        </span>
                    ))}
            </div>
        </div>
    )
}

export default Card
