import { graphql, useStaticQuery, Link } from 'gatsby'
import * as React from 'react'
import Contact from '../components/contact'
import Header from '../components/header'
import Hero from '../components/hero'
import Services from '../components/Services'
import Skills from '../components/skills'
import WorkTogether from '../components/workTogether'
import Seo from '../components/seo'

// markup
const IndexPage = () => {
    const data = useStaticQuery(graphql`
        query GetSiteInfo {
            site {
                siteMetadata {
                    title
                    description
                }
            }
        }
    `)

    const meta = data?.site?.siteMetadata ?? {}

    return (
        <>
            <Seo></Seo>
            <main className="bg-gray-50">
                <Header></Header>
                <Hero></Hero>
                <Skills></Skills>
                <WorkTogether></WorkTogether>
                <Services></Services>
                <Contact></Contact>
            </main>
        </>
    )
}

export default IndexPage
