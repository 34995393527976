import React from 'react'
import Card from './card'
import Forfait from '../images/undraw_dev_productivity.svg'
import Regie from '../images/undraw_teamwork.svg'

const WorkTogether = () => {
    return (
        <section className="container mx-auto relative mt-20 mb-10 p-16 md:mb-2 sm:mt-10 sm:p-10 ">
            <h2 className="font-bold text-6xl mb-20 section-underline sm:text-5xl sm:mb-10">
                Comment travailler ensemble
            </h2>

            <div className="flex justify-around flex-wrap mt-[-30px]">
                <Card
                    title="Au forfait"
                    img={Forfait}
                    tags={[
                        'Homeworking',
                        'Efficience',
                        'Communication',
                        'Qualité',
                    ]}
                >
                    Besoin d'un produit clé en main, j'analyse votre besoin et
                    vous fourni une solution applicative pour y répondre.
                </Card>
                <Card
                    title="En régie"
                    img={Regie}
                    tags={[
                        "Cohésion d'équipe",
                        'Qualité',
                        'Communication',
                        'Echanges',
                    ]}
                >
                    Besoin de renforcer votre équipe de développeurs ou de
                    développer un projet en interne, j'intègre votre structure
                    pour répondre à vos besoins
                </Card>
            </div>
        </section>
    )
}

export default WorkTogether
