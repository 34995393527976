import React, { useEffect } from 'react'

const Contact = () => {
    useEffect(() => {
        document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault()

                document
                    .querySelector(this.getAttribute('href'))
                    .scrollIntoView({
                        behavior: 'smooth',
                    })
            })
        })
    }, [])

    return (
        <section className="container mx-auto p-16 sm:p-10 ">
            <div className="flex justify-center">
                <h1 className=" font-bold text-6xl inline-block mb-10 relative text-center section-underline after:w-1/5 sm:text-5xl sm:text-left">
                    Me contacter
                </h1>
            </div>
            <div className="flex items-center  ">
                <div className="w-4/6  mx-auto shadow-xl  bg-white p-5 rounded-xl sm:w-full  ">
                    <div className="m-7 sm:m-0">
                        <form
                            name="Contact Form"
                            method="POST"
                            id="form"
                            data-netlify="true"
                        >
                            <input
                                type="hidden"
                                name="form-name"
                                value="Contact Form"
                            />
                            <div className="mb-6">
                                <label
                                    for="name"
                                    className="block mb-2 text-sm text-gray-600 "
                                >
                                    Nom complet
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="John Doe"
                                    required
                                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 "
                                />
                            </div>
                            <div className="mb-6">
                                <label
                                    for="email"
                                    className="block mb-2 text-sm text-gray-600 "
                                >
                                    Adresse mail
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="you@company.com"
                                    required
                                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300  "
                                />
                            </div>
                            <div className="mb-6">
                                <label
                                    for="phone"
                                    className="text-sm text-gray-600 dark:text-gray-400"
                                >
                                    Numéro de téléphone
                                </label>
                                <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    placeholder="06 99 99 99 99"
                                    required
                                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 "
                                />
                            </div>
                            <div className="mb-6">
                                <label
                                    for="message"
                                    className="block mb-2 text-sm text-gray-600 dark:text-gray-400"
                                >
                                    Votre message
                                </label>

                                <textarea
                                    rows="5"
                                    name="message"
                                    id="message"
                                    placeholder="Votre message"
                                    className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
                                    required
                                ></textarea>
                            </div>
                            <div className="mb-6">
                                <button
                                    type="submit"
                                    className="w-full px-3 py-4 text-white bg-blue-300 rounded-md focus:bg-blue-400 focus:outline-none"
                                >
                                    Envoyer
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact
