import React from 'react'
import reactLogo from '../images/react.png'
import nodeLogo from '../images/node.png'
import efCoreLogo from '../images/efCore.png'
import netCoreLogo from '../images/netCore.png'
import mongoDBLogo from '../images/mongoDB.png'
import sqlServerLogo from '../images/sqlServer.png'
import sharepointLogo from '../images/sharepoint.png'
import jsLogo from '../images/js.svg'
import typescriptLogo from '../images/typescript.svg'
import { gsap } from 'gsap'
import { useEffect } from 'react'

const Skills = () => {
    useEffect(() => {
        gsap.to(
            '.skill',
            {
                ease: 'power2.out',
                left: '0',
                duration: 1,
                stagger: -0.1,
                delay: 1,
            },
            0.05
        )
    }, [])
    return (
        <section className="relative mt-20">
            <div className="relative inset-0 w-screen h-full bg-gradient-to-tr from-gray-300 dark:from-dark-secondary-light dark:to-dark-secondary-dark to-gray-500 transform -skew-y-6 z-0 ">
                <div className="container relative mx-auto flex flex-col pb-28 items-center z-10 skew-y-6 ">
                    <h2 className="font-bold text-6xl text-white p-16 section-underline sm:text-5xl">
                        Ma stack technique
                    </h2>
                    <div className="flex justify-center flex-wrap w-11/12 mt-[-30px]">
                        <img
                            className="h-24 skill relative left-[-200%] ml-4 mt-[30px] sm:h-20"
                            src={reactLogo}
                            alt="react-logo"
                        ></img>
                        <img
                            className="h-24 skill relative left-[-200%] ml-4 mt-[30px] sm:h-20"
                            src={nodeLogo}
                            alt="react-logo"
                        ></img>
                        <img
                            className="h-24 skill relative left-[-200%] ml-4 mt-[30px] sm:h-20"
                            src={efCoreLogo}
                            alt="react-logo"
                        ></img>
                        <img
                            className="h-24 skill relative left-[-200%] ml-4 mt-[30px] sm:h-20"
                            src={netCoreLogo}
                            alt="react-logo"
                        ></img>
                        <img
                            className="h-24 skill relative left-[-200%] ml-4 mt-[30px] sm:h-20"
                            src={mongoDBLogo}
                            alt="react-logo"
                        ></img>
                        <img
                            className="h-24 skill relative left-[-200%] ml-4 mt-[30px] sm:h-20"
                            src={sqlServerLogo}
                            alt="react-logo"
                        ></img>
                        <img
                            className="h-24 skill relative left-[-200%] ml-4 mt-[30px] sm:h-20"
                            src={sharepointLogo}
                            alt="react-logo"
                        ></img>
                        <img
                            className="h-24 skill relative left-[-200%] ml-4 mt-[30px] sm:h-20"
                            src={jsLogo}
                            alt="react-logo"
                        ></img>
                        <img
                            className="h-24 skill relative left-[-200%] ml-4 mt-[30px] sm:h-20"
                            src={typescriptLogo}
                            alt="react-logo"
                        ></img>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Skills
