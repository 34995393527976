import React from 'react'

const Hero = () => {
    return (
        <div className="container mx-auto flex mt-10 px-16 justify-between sm:p-6 sm:mt-0">
            <div className="w-2/5 md:w-full">
                <h2 className="font-bold text-6xl p-4 mb-8 sm:text-5xl">
                    Construisons
                    <br />
                    ensemble
                </h2>
                <p className="text-xl border-l-8 border-blue-300 pl-4">
                    Je m'apelle{' '}
                    <span className="font-bold text-gray-400">
                        Alexandre Kubik
                    </span>
                    , développeur Fullstack passionné, j'interviens pour vous
                    aider à concrétiser vos projets, de l'étude au développement
                    ou en renforcement de votre équipe.
                </p>
                <a
                    href="#form"
                    className="inline-block text-xl mt-10 bg-gray-400 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded cursor-pointer"
                >
                    Nouveau projet
                </a>
            </div>
            <div className="flex">
                <svg
                    className="h-80 md:hidden block p-4 lg:h-60 lg:self-center "
                    id="b66c9c51-8109-402d-a3f9-100a5bb3d153"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 890.30174 489.29978"
                >
                    <title>work_together</title>
                    <rect
                        x="182.97245"
                        y="0.39886"
                        width="703.57565"
                        height="450.60114"
                        fill="#e6e6e6"
                    />
                    <rect
                        x="245.18701"
                        y="60.90722"
                        width="254"
                        height="261"
                        fill="#fff"
                    />
                    <rect
                        x="594.18701"
                        y="65.96874"
                        width="254"
                        height="317"
                        fill="#fff"
                    />
                    <ellipse
                        cx="844.80174"
                        cy="477.2616"
                        rx="45.5"
                        ry="6.5"
                        fill="#e6e6e6"
                    />
                    <ellipse
                        cx="389.89961"
                        cy="477.40722"
                        rx="45.5"
                        ry="6.5"
                        fill="#e6e6e6"
                    />
                    <ellipse
                        cx="104.42498"
                        cy="470.90722"
                        rx="104.42498"
                        ry="5"
                        fill="#e6e6e6"
                    />
                    <rect
                        x="182.67203"
                        width="703.57565"
                        height="29.89047"
                        fill="#93c5fd"
                    />
                    <circle
                        cx="204.88423"
                        cy="15.28159"
                        r="5.53997"
                        fill="#e6e6e6"
                    />
                    <circle
                        cx="225.91256"
                        cy="15.28159"
                        r="5.53997"
                        fill="#e6e6e6"
                    />
                    <circle
                        cx="246.94089"
                        cy="15.28159"
                        r="5.53997"
                        fill="#e6e6e6"
                    />
                    <path
                        d="M947.03614,356.34879H945.4975V314.19823a24.3956,24.3956,0,0,0-24.39567-24.39563H831.80022a24.3956,24.3956,0,0,0-24.39567,24.39563V545.43948a24.3956,24.3956,0,0,0,24.39567,24.39563h89.30161a24.3956,24.3956,0,0,0,24.39567-24.39563V386.35227h1.53864Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#3f3d56"
                    />
                    <path
                        d="M921.08621,296.1495H909.42939a8.6555,8.6555,0,0,1-8.01381,11.92446H850.25579A8.65552,8.65552,0,0,1,842.242,296.1495H831.35448a18.21836,18.21836,0,0,0-18.21838,18.21834v230.902a18.21836,18.21836,0,0,0,18.21838,18.21836h89.73173a18.21837,18.21837,0,0,0,18.21839-18.21836v-230.902A18.21837,18.21837,0,0,0,921.08621,296.1495Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#fff"
                    />
                    <rect
                        x="284.09023"
                        y="225.77561"
                        width="176.5444"
                        height="8.05267"
                        fill="#e6e6e6"
                    />
                    <rect
                        x="284.09023"
                        y="251.77561"
                        width="176.5444"
                        height="8.05267"
                        fill="#93c5fd"
                    />
                    <rect
                        x="284.09023"
                        y="277.77561"
                        width="176.5444"
                        height="8.05267"
                        fill="#e6e6e6"
                    />
                    <rect
                        x="670.83934"
                        y="243.34556"
                        width="103.06376"
                        height="8.05267"
                        fill="#e6e6e6"
                    />
                    <rect
                        x="670.83934"
                        y="268.34556"
                        width="103.06376"
                        height="8.05267"
                        fill="#e6e6e6"
                    />
                    <rect
                        x="670.83934"
                        y="293.34556"
                        width="103.06376"
                        height="8.05267"
                        fill="#e6e6e6"
                    />
                    <rect
                        x="922.35071"
                        y="606.56341"
                        width="17.17729"
                        height="17.17729"
                        transform="translate(1391.24229 -521.13741) rotate(90)"
                        fill="#3f3d56"
                    />
                    <rect
                        x="894.67619"
                        y="606.56341"
                        width="17.17729"
                        height="17.17729"
                        transform="translate(1363.56776 -493.46289) rotate(90)"
                        fill="#93c5fd"
                    />
                    <rect
                        x="867.00166"
                        y="606.56341"
                        width="17.17729"
                        height="17.17729"
                        transform="translate(1335.89323 -465.78836) rotate(90)"
                        fill="#3f3d56"
                    />
                    <rect
                        x="839.32713"
                        y="606.56341"
                        width="17.17729"
                        height="17.17729"
                        transform="translate(1308.2187 -438.11383) rotate(90)"
                        fill="#3f3d56"
                    />
                    <rect
                        x="811.6526"
                        y="606.56341"
                        width="17.17729"
                        height="17.17729"
                        transform="translate(1280.54418 -410.4393) rotate(90)"
                        fill="#3f3d56"
                    />
                    <rect
                        x="692.7424"
                        y="138.9903"
                        width="57.25764"
                        height="57.25764"
                        fill="#93c5fd"
                    />
                    <path
                        d="M504.45442,480.943l-.74114,1.48227-2.2234-11.117s-2.96454-11.85815,2.96454-11.85815,4.4468,11.85815,4.4468,11.85815v8.15248Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#a0616a"
                    />
                    <path
                        d="M601.69555,479.42524l.74113,1.48227,2.22341-11.117s2.96454-11.85815-2.96454-11.85815-4.44681,11.85815-4.44681,11.85815V477.943Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#a0616a"
                    />
                    <polygon
                        points="382.215 358.94 373.322 413.784 377.768 467.145 388.144 462.699 386.662 422.677 397.038 390.808 399.261 428.606 394.815 467.886 408.155 469.369 417.79 388.585 417.049 361.163 382.215 358.94"
                        fill="#2f2e41"
                    />
                    <path
                        d="M537.06434,665.82526l-5.18794,8.15248V692.194a2.209,2.209,0,0,0,1.85635,2.18045c2.5428.41721,6.97037.71364,8.51954-1.86831,2.2234-3.70567.74113-11.85816.74113-11.85816s2.96454-5.92907,1.48227-10.37588-2.96454-5.18794-2.96454-5.18794Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#2f2e41"
                    />
                    <path
                        d="M559.29839,673.23661l1.48227,2.2234s2.2234,2.22341.74113,3.70568a28.96565,28.96565,0,0,0-2.96454,3.70567s-8.15248,2.2234-8.15248-.74114v-9.63475Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#2f2e41"
                    />
                    <circle
                        cx="401.77827"
                        cy="288.79506"
                        r="9.82427"
                        fill="#a0616a"
                    />
                    <polygon
                        points="395.185 291.867 394.444 305.949 407.784 306.69 407.043 292.608 395.185 291.867"
                        fill="#a0616a"
                    />
                    <path
                        d="M564.48633,512.41037l-2.10384-5.13957s-9.75432-3.75405-12.71885-.04838l-1.48227,4.44681-11.85816-2.96454-6.67021,14.8227,5.92908,42.24468s15.56382,20.01064,36.3156.74113l5.92908-40.02127L573.38,510.9281Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#575a89"
                    />
                    <polygon
                        points="417.049 308.543 418.531 305.578 433.354 296.684 442.4 269.907 449.4 272.907 441.506 304.837 420.013 324.847 417.049 308.543"
                        fill="#575a89"
                    />
                    <polygon
                        points="382.956 304.837 381.474 303.355 365.91 293.72 354.793 271.486 345.9 274.45 355.534 301.872 379.251 321.142 382.956 304.837"
                        fill="#575a89"
                    />
                    <path
                        d="M563.91892,479.10047l1.60629-.80315-2.40943-.80314V476.691s-12.04715-4.81886-13.65343,3.21257a16.14329,16.14329,0,0,0,0,19.27544v2.40942l12.85029,1.60629,1.60628-4.81886S571.95036,483.91933,563.91892,479.10047Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#2f2e41"
                    />
                    <path
                        d="M269.93891,565.15978l-2.85542,1.42771s-11.42168,5.23493-8.09036,8.09035,12.37349-2.85542,12.37349-2.85542l1.90361-2.37952Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#ffb8b8"
                    />
                    <polygon
                        points="198.633 359.099 189.807 418.346 176.481 459.749 169.343 456.418 176.005 399.786 156.969 383.605 158.397 359.81 198.633 359.099"
                        fill="#2f2e41"
                    />
                    <path
                        d="M323.24006,663.19583s8.09036-4.28313,8.56626,1.90361l-.95181,6.18674s2.85542,10.94578-6.66264,8.56626-2.85542-9.51806-2.85542-9.51806Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#2f2e41"
                    />
                    <path
                        d="M318.00513,583.72l-6.18674,5.23493s0,32.36142,4.28312,37.12045,31.40961,29.0301,32.36142,29.9819,5.23494-9.51806,5.23494-9.51806l-25.69878-29.9819,1.90362-18.56023Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#2f2e41"
                    />
                    <path
                        d="M350.84245,645.11151s7.61445-1.42771,7.61445,3.80722a115.23116,115.23116,0,0,0,.9518,12.37349s1.90362,16.65661-4.28313,14.27709-8.56625-19.03612-8.56625-19.03612a7.08933,7.08933,0,0,1,2.85542-3.33133C351.31835,652.25006,350.84245,645.11151,350.84245,645.11151Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#2f2e41"
                    />
                    <polygon
                        points="172.198 288.424 173.626 306.033 169.819 312.695 159.349 302.701 158.397 289.376 172.198 288.424"
                        fill="#ffb8b8"
                    />
                    <circle
                        cx="163.6319"
                        cy="282.71335"
                        r="11.42168"
                        fill="#ffb8b8"
                    />
                    <polygon
                        points="168.391 305.557 173.15 291.756 181.716 301.274 197.421 361.713 185.523 368.852 180.289 361.237 179.337 371.231 156.017 366.472 154.114 302.701 158.87 296.002 168.391 305.557"
                        fill="#d0cde1"
                    />
                    <polygon
                        points="161.728 373.135 158.873 296.039 152.686 300.798 141.264 308.412 148.879 335.539 151.258 364.093 161.728 373.135"
                        fill="#2f2e41"
                    />
                    <polygon
                        points="144.596 308.888 141.264 308.412 131.746 336.49 112.71 360.286 119.849 367.9 141.74 346.484 149.831 328.876 144.596 308.888"
                        fill="#2f2e41"
                    />
                    <path
                        d="M351.4174,565.59536l-.64933,3.12573s-2.97081,12.20793,1.33475,11.36336,5.4281-11.48008,5.4281-11.48008l-.68754-2.96869Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#ffb8b8"
                    />
                    <polygon
                        points="172.406 290.983 195.993 300.322 193.138 328.876 198.849 360.286 193.138 366.472 172.022 295.028 172.406 290.983"
                        fill="#2f2e41"
                    />
                    <path
                        d="M319.079,468.8806l3.35262-1.6763v1.6763l2.23507-1.11753v2.79384s8.38153,6.14645,6.70522,13.41044-1.67631,7.82276-1.67631,7.82276-2.11922-13.81412-10.50074-11.57905-9.05615,2.63876-11.29122,8.78521l-.60636,2.63647S297.84583,469.99814,319.079,468.8806Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#2f2e41"
                    />
                    <polygon
                        points="192.662 300.322 196.469 300.322 202.656 333.635 202.656 362.665 195.042 361.237 188.855 317.454 192.662 300.322"
                        fill="#2f2e41"
                    />
                    <rect
                        x="3.53501"
                        y="370.77159"
                        width="140.32096"
                        height="97.51118"
                        fill="#93c5fd"
                    />
                    <path
                        d="M228.54462,595.14827a29.729,29.729,0,1,0,29.729,29.729A29.81642,29.81642,0,0,0,228.54462,595.14827Zm0,8.9187a8.91871,8.91871,0,1,1-8.91871,8.91871,8.94863,8.94863,0,0,1,8.91871-8.91871Zm0,42.93556a21.65737,21.65737,0,0,1-17.83742-9.519c.14293-5.94581,11.89161-9.21886,17.83742-9.21886s17.69448,3.27305,17.8374,9.21886a21.69344,21.69344,0,0,1-17.83741,9.519Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#fff"
                    />
                    <rect
                        x="284.18701"
                        y="90.90722"
                        width="176"
                        height="101"
                        fill="#e6e6e6"
                    />
                    <path
                        d="M963.0513,496.58366l.81548-5.25988s4.75311-10.80516.99606-11.38765-6.89084,9.70426-6.89084,9.70426l1.37467,10.98574Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#ffb8b8"
                    />
                    <polygon
                        points="846.67 321.051 813.089 314.306 809.187 289.847 799.302 289.084 799.785 320.707 828.962 336.004 846.67 321.051"
                        fill="#575a89"
                    />
                    <polygon
                        points="846.67 321.051 813.089 314.306 809.187 289.847 799.302 289.084 799.785 320.707 828.962 336.004 846.67 321.051"
                        opacity="0.2"
                    />
                    <rect
                        x="910.1623"
                        y="482.06175"
                        width="103.06376"
                        height="8.05267"
                        transform="translate(1768.53923 766.82607) rotate(-180)"
                        fill="#93c5fd"
                    />
                    <path
                        d="M956.49034,494.78044l.00669-5.32271s3.05587-11.402-.74606-11.40676-5.33608,10.63873-5.33608,10.63873l3.02818,10.64924Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#ffb8b8"
                    />
                    <path
                        d="M1000.2023,510.02489l4.60746-1.12311a11.86169,11.86169,0,0,0,11.64163-14.21088h0a15.50416,15.50416,0,0,0-15.48468-15.52363h0A15.50417,15.50417,0,0,0,985.443,494.65194v0A12.32065,12.32065,0,0,0,1000.2023,510.02489Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#2f2e41"
                    />
                    <polygon
                        points="818.254 381.458 822.005 422.524 828.797 462.833 840.203 462.847 838.732 423.305 832.709 375.393 818.254 381.458"
                        fill="#2f2e41"
                    />
                    <path
                        d="M985.17015,665.90379l-7.6096,4.55277s-12.1681,1.50549-9.89171,5.31029,28.89279,1.55707,28.8947.03629-.748-9.886-2.26874-9.88789Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#2f2e41"
                    />
                    <path
                        d="M1006.77139,583.2605s-.21394,5.87095-5.54811,14.98891l.72505,28.13527,27.33,35.01218-9.89553,8.35184-33.40927-38.06137-8.307-45.63372,10.65879-10.63205Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#2f2e41"
                    />
                    <path
                        d="M1026.99716,661.394s9.12274,1.53224,7.59719,5.33226-5.34945,21.28415-15.23162,18.99057c0,0-4.56232-.00573-3.03773-3.04536a55.0044,55.0044,0,0,1,3.04824-5.31889l.01241-9.885Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#2f2e41"
                    />
                    <circle
                        cx="840.41522"
                        cy="294.04136"
                        r="11.40581"
                        fill="#ffb8b8"
                    />
                    <polygon
                        points="845.728 301.652 845.712 314.578 833.546 314.563 835.083 301.639 845.728 301.652"
                        fill="#ffb8b8"
                    />
                    <path
                        d="M985.35926,515.34718l3.68652-.9056,11.52122.9247s6.07545,6.09074,5.31316,7.61055-4.611,38.774-4.611,38.774l6.81006,26.62213-24.33429,1.49021-12.16428-1.53605,2.34133-47.90151s-5.30647-12.93326,1.54178-16.7266A29.95429,29.95429,0,0,0,985.35926,515.34718Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#575a89"
                    />
                    <polygon
                        points="844.193 313.056 809.978 311.492 802.404 287.911 792.518 288.658 797.801 319.841 828.964 330.526 844.193 313.056"
                        fill="#575a89"
                    />
                    <circle
                        cx="862.43241"
                        cy="278.27937"
                        r="7.35883"
                        fill="#2f2e41"
                    />
                    <path
                        d="M1010.49168,479.67357a7.35536,7.35536,0,0,1,8.807-7.20494A7.3554,7.3554,0,1,0,1014.711,486.331,7.35277,7.35277,0,0,1,1010.49168,479.67357Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#2f2e41"
                    />
                    <path
                        d="M1004.17862,483.847l-9.92079-1.82669-7.62952,4.78184a11.57336,11.57336,0,0,0-5.33128,11.29456l7.64125-1.93681,1.71106-4.29045,2.03787,3.34024,8.26714,14.45412,8.37-4.55181,5.33322-8.35757Z"
                        transform="translate(-154.84913 -205.35011)"
                        fill="#2f2e41"
                    />
                    <rect
                        x="526.03635"
                        y="245.29674"
                        width="1.99958"
                        height="202.92117"
                        transform="translate(-190.8885 425.93087) rotate(-60.15005)"
                        fill="#fff"
                    />
                    <rect
                        x="425.57556"
                        y="345.75754"
                        width="202.92117"
                        height="1.99958"
                        transform="translate(-257.51689 102.92685) rotate(-29.84553)"
                        fill="#fff"
                    />
                    <rect
                        x="875.53625"
                        y="332.45224"
                        width="1.99979"
                        height="80.61017"
                        transform="translate(-161.69687 523.63263) rotate(-45)"
                        fill="#fff"
                    />
                    <rect
                        x="836.23106"
                        y="371.75743"
                        width="80.61017"
                        height="1.99979"
                        transform="translate(-161.69687 523.63263) rotate(-45)"
                        fill="#fff"
                    />
                </svg>
            </div>
        </div>
    )
}

export default Hero
