import { graphql, useStaticQuery, Link } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

const Seo = (props) => {
    const data = useStaticQuery(graphql`
        query GetSiteMetadata {
            site {
                siteMetadata {
                    description
                    image
                    siteUrl
                    title
                }
            }
        }
    `)

    const defaults = data?.site?.siteMetadata

    const title = props.title || defaults.title
    const description = props.description || defaults.description
    const image = new URL(props.image || defaults.image, defaults.siteUrl)
    const url = new URL(props.path || '/', defaults.siteUrl)

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description}></meta>
            <Link rel="canonical" href={url}></Link>
            {image && <meta name="image" content={image}></meta>}

            <meta property="og:url" content={url}></meta>
            <meta property="og:type" content="website"></meta>
            <meta property="og:title" content={title}></meta>
            <meta property="og:description" content={description}></meta>
            {image && <meta name="og:image" content={image}></meta>}
        </Helmet>
    )
}

export default Seo
