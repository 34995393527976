import React from 'react'
import logo from '../images/logo.svg'

const Header = () => {
    return (
        <section className=" font-bold ">
            <div className="container mx-auto px-16">
                <img
                    className="sm:h-48 h-60 mx-auto "
                    src={logo}
                    alt="logo"
                ></img>
            </div>
        </section>
    )
}

export default Header
